/* Assure que le body et html n'ont pas de marges ou de padding */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
  }
  
  .container {
    position: relative;
    width: 100%;
    height: 100vh; /* Hauteur de la vue entière */
    overflow: hidden;
  }
  
  .image-block {
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Assure que l'image couvre le conteneur sans déformation */
    object-position: center; /* Centre l'image dans le conteneur */
  }
  
  .form-block {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.9); /* Fond semi-transparent */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    width: 90%;
    max-width: 500px;
  }
  
  /* Ajustement pour petits écrans */
@media (max-width: 768px) {
    .form-block {
      top: 40%; /* Positionnement vertical sur les petits écrans */
      width: 95%;
      padding: 15px;
      margin-top: 70px;
    }
  }

  h1 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  label {
    display: block;
    margin-bottom: 5px;
    color: #555;
  }
  
  input[type="number"],
  select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .submit-button {
    width: 100%;
    padding: 10px;
    background-color: #5cb85c;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .submit-button:hover {
    background-color: #4cae4c;
  }
  
  .back-link {
    display: block;
    text-align: center;
    margin-top: 10px;
    color: #007bff;
    text-decoration: none;
  }
  
  .back-link:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .form-block {
      width: 95%;
      padding: 10px;
    }
  }
  


  /* Home.css */
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  z-index: 1000;
}
