/* Modal.css */

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.modal-content {
  background: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 95%;
  min-height: 95%;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: #e91e63;
  color: #fff;
  font-size: 18px;
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 50%;
  transition: background 0.3s;
}

.modal-close:hover {
  background: #c2185b;
}

h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
}

h3 {
  font-size: 20px;
  color: #555;
  margin-top: 20px;
  margin-bottom: 15px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

th, td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background: #f4f4f4;
  font-weight: bold;
}

tr:nth-child(even) {
  background: #f9f9f9;
}

tr:hover {
  background: #f1f1f1;
}

strong {
  color: #e91e63;
}



/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background: #ffffff;
  padding: 20px;
  border-radius: 12px;
  max-width: 90%;
  width: 90%;
  max-height: 80%;
  overflow-y: auto;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: #e91e64;
  border: none;
  color: #ffffff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  cursor: pointer;
}

.modal-close-button:hover {
  background: #c62828;
}

.modal-header {
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
  font-size: 24px;
  color: #333;
}

.result-section {
  margin-bottom: 20px;
}

.result-section h3 {
  margin-bottom: 10px;
  font-size: 20px;
  color: #555;
}

.result-section ul {
  list-style-type: none;
  padding: 0;
}

.result-section div {
  margin-bottom: 8px;
  font-size: 16px;
  color: black;/*#666*/
}

.result-section li::before {
  content: "• ";
  color: #007bff;
}


/**

.result-section li {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  font-size: 16px;
  color: #666;
}

.result-section li svg {
  margin-right: 8px;
  color: #007bff;
}
**/




.email-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 500px;
  text-align: center;
}

.email-input {
  width: 80%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.email-submit-button, .email-cancel-button {
  padding: 10px;
  margin: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.email-submit-button {
  background-color: #06a5ba;
  color: white;
}

.email-cancel-button {
  background-color: #e91e63;
  color: white;
}


