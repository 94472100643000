/*
@import url('https://fonts.googleapis.com/css2?family=Gentium+Book+Plus:ital,wght@0,400;0,700;1,400;1,700&display=swap');
*{
  font-family: "Gentium Book Plus", serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}
*/
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

nav,footer,.MuiBottomNavigation-root{
  background-color: #e91e63 !important;  
}
ul{
  background-color: transparent !important;
}


.MuiBottomNavigation-root{
  background-color: #e91e63 !important;  /**#e91e63**/
  font-weight: 700;
  font-size: 17px;
}

  @media (min-width: 768px) {
    .desktop-space {
      display: block;
    }
  }
  @media (max-width: 767px) {
    .desktop-space {
      display: none;
    }
    .desktop-space2 {
      display: none;
    }
    .mobile-space {
      display: block;
    }
  }




.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.css-hlgwow {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  border: 0px solid #fffdfd;
  align-items: center;
  padding: 6px !important;
  display: grid;
  box-shadow: 0 2px 8px #00000026;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-overflow-scrolling: touch;
  position: relative;
  overflow: hidden;
  padding: 2px 8px;
  box-sizing: border-box;
}



.swal2-confirm.swal2-styled{background:#E91E63 !important;}


.custom-select {
  min-width: 100%; /* Définir une largeur minimale fixe */
}



.MuiBottomNavigationAction-label.Mui-selected,.MuiBottomNavigationAction-label {
  font-size: 13px !important;
  font-family: "PT Serif", serif !important;
}